<template>
  <v-app>
    <div class="app-loader" v-if="isAccountsFetchWaiting">
      <span class="app-loader__message">Loading all accounts...</span>
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-else>
      <v-main>
        <v-container fluid>
          <v-container class="pa-1">
            <div class="d-flex">
              <span class="display-1 span-header">Partner Accounts:</span>
              <v-spacer></v-spacer>
              <v-btn to="master/partner"><v-icon class="mr-2">mdi-account-plus-outline</v-icon>Add Reseller</v-btn>
            </div>
            <v-item-group v-model="selected">
              <v-row>
                <v-col
                  v-for="(account, i) in resellerAccountList"
                  :key="i"
                  cols="6"
                  md="6"
                >
                  <v-item>
                    <v-card
                      class="align-center"
                      height="120"
                      color="#e8edfc"
                      @click="selectAccount(account.id, 'reseller')"
                    >
                      <div class="account-info text-center">
                        <v-avatar color="rgb(188,188,255)">
                          <span class="primary--text">{{
                            account.code.substr(0, 2).toUpperCase()
                          }}</span>
                        </v-avatar>
                        <div class="display-1">{{ account.code }}</div>
                      </div>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>

            <v-item-group v-model="selected">
              <v-row>
                <v-col
                  v-for="(account, i) in wlAccountList"
                  :key="i"
                  cols="6"
                  md="6"
                >
                  <v-item>
                    <v-card
                      class="align-center"
                      height="120"
                      @click="selectAccount(account.id, 'whitelabel')"
                    >
                      <div class="account-info text-center">
                        <v-avatar color="rgb(188,188,255)">
                          <span class="primary--text">{{
                            account.name.substr(0, 2).toUpperCase()
                          }}</span>
                        </v-avatar>
                        <div class="display-1">{{ account.name.toUpperCase() }}</div>
                      </div>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-container>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      realms: "realm/realms",
      user: "user/user",
      accountsFetchError: "partner/fetchError",
      isAccountsFetchWaiting: "partner/isFetchWaiting",
      resellerAccountList: "partner/resellerAccountList",
      wlAccountList: "partner/wlAccountList",
    }),
  },
  methods: {
    ...mapActions({
      setActiveAccount: "partner/setActiveAccount",
      setLogout: "user/logout",
      fetchAccounts: "partner/fetchAccounts",
      setActiveRealmId: "realm/setActiveRealmId",
    }),
    selectAccount(accountId, accountType) {
      this.setActiveRealmId("shopman");
      this.setActiveAccount({ accountId, accountType });
      this.$router.push("/partner/home");
    },
    logout() {
      this.setLogout()
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.home {
  min-height: 100%;
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.account-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.span-header {
  text-decoration-line: underline;
  margin-bottom: 10px;
}
</style>
